/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

import $ from 'jquery';
import 'jquery-migrate';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/slider';
import 'bootstrap';
import 'bootstrap-select';
import bsCustomFileInput from 'bs-custom-file-input';
import 'jquery.mmenu';
import 'jquery.stellar/jquery.stellar.js';
import './lib/ace-responsive-menu';
import './lib/jquery-scrolltofixed-min.js';
import { WOW } from 'wow.js/dist/wow.js';
import './lib/owl.carousel.js';
import { Gmap } from './util/map.js';
import { MainController } from './controller/main.js';

// Globales usados en otros .js
global.$ = global.jQuery = $;
global.WOW = WOW;
global.MainController = MainController;
global.Gmap = Gmap;

// jQuery Migrate: Para eliminar los warnings en la consola
jQuery.migrateMute = true;

// Inicializacion de bsCustomFileInput
bsCustomFileInput.init();
