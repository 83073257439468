'use strict';

const MAX_PRICE = 1000000000;
const MAX_AREA = 1000000;

/**
 * App Controller
 */
class MainController
{
  initialize() {
    this.initPreloader();
    this.navbarScrollFixed();
    this.initScrollToHome();
    this.initScrollToTop();
    this.initWowAnimation();
    this.initMobileNavToggle();
    this.initTextToggle();
    this.initShowFilter();
    this.initShowButtons();
    this.initResponsiveMenu();
    this.initMouseScroll();
    this.initCollapseButtons();
    this.initMMenu();
    this.initBackgroundParallax();
    this.initMagnificPopup();
    this.initTabShow();
    this.initDatePicker();
    this.initFeaturedPropertiesCarousel();
    this.initFeaturedPropertiesSidebarCarousel()
    this.initTopCarousel();
    this.initRangeSliders();
    this.initSearchPanel();
    this.initFilterButtons();
    this.initOverlay();
    this.initModalityButtons();
    this.initGeoLocation();
    this.initFormCleanup();
  }

  initPreloader() {
    if ($('.preloader').length > 0) {
      $('.preloader').delay( 200).fadeOut( 300);
    }

    $('.preloader_disabler').on( 'click', () => $('#preloader').hide());
  }

  navbarScrollFixed() {
    $('.navbar-scrolltofixed').scrollToFixed();

    let summaries = $('.summary');

    summaries.each( (index, summary) => {
      let next = summaries[index + 1];

      summary.scrollToFixed({
        marginTop: $('.navbar-scrolltofixed').outerHeight(true) + 10,
        zIndex: 999,

        limit: function() {
          let limit = 0;

          if (next) {
            limit = $(next).offset().top - $(this).outerHeight(true) - 10;
          } else {
            limit = $('.footer').offset().top - $(this).outerHeight(true) - 10;
          }

          return limit;
        }
      });
    });
  }

  initMobileNavToggle() {
    if ($('#main-nav-bar .navbar-nav .sub-menu').length > 0) {
      const subMenu = $('#main-nav-bar .navbar-nav .sub-menu');

      subMenu.parent( 'li')
             .children( 'a')
             .append( () => '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>');

      const subNavToggler = $('#main-nav-bar .navbar-nav .sub-nav-toggler');

      subNavToggler.on( 'click', e => {
        const el = $(e.currentTarget);

        el.parent()
          .parent()
          .children('.sub-menu')
          .slideToggle();

        return false;
      });
    };
  }

  initWowAnimation() {
    const wow = new WOW({
      animateClass: 'animated',
      mobile: true,
      offset:       0
    });

    wow.init();
  }

  initScrollToHome() {
    $(window).scroll( e => {
      if ($(e.currentTarget).scrollTop() > 600) {
        $('.scrollToHome').fadeIn();
      } else {
        $('.scrollToHome').fadeOut();
      }
    });

    $('.scrollToHome').on('click', () => {
      $('html, body').animate({
        scrollTop: 0
      }, 800);

      return false;
    });
  }

  initScrollToTop() {
    this._manageScroll();

    $(window).on( 'scroll', e => this._manageScroll( e));
  }

  initTextToggle() {
    $.fn.extend({
      toggleText: function(a, b) {
        return this.text( this.text() == b ? a : b);
      }
    });

    $.fn.extend({
      toggleText2: function(a, b) {
        return this.text( this.text() == b ? a : b);
      }
    });
  }

  initShowFilter() {
    if ($('.showFilter').length > 0) {
      $('.showFilter').on('click', e => {
        let el = $(e.currentTarget);

        el.toggleText('Show Filter', 'Hide Filter');
        el.toggleClass('flaticon-close flaticon-filter-results-button sidebarOpended sidebarClosed');
        $('.listing_toogle_sidebar.sidenav').toggleClass('opened');
        $('.body_content').toggleClass('translated');
      });
    }
  }

  initShowButtons() {
    if ($('.showBtns').length > 0) {
      $('.showBtns').click( e => {
        let el = $(e.currentTarget);

        el.toggleText2('Show Filter', 'Hide Filter');
        el.toggleClass('flaticon-close flaticon-filter-results-button sidebarOpended2 sidebarClosed2');
        $('.sidebar_content_details').toggleClass('is-full-width');
      });
    }
  }

  initResponsiveMenu() {
    $('#respMenu').aceResponsiveMenu({
      resizeWidth: '768',
      animationSpeed: 'fast',
      accoridonExpAll: false
    });
  }

  initMouseScroll() {
    $('.mouse_scroll, .mouse_scroll.home8').on('click', () => {
      $('html, body').animate({
        scrollTop: $('#feature-property, #property-city').offset().top
      }, 1000);
    });
  }

  initCollapseButtons() {
    $('.collapse').on( 'show.bs.collapse',
      e => $(e.currentTarget).siblings('.card-header')
                             .addClass('active')
    );

    $('.collapse').on( 'hide.bs.collapse',
      e => $(e.currentTarget).siblings('.card-header')
                             .removeClass('active')
    );
  }

  initMMenu() {
    $('nav#menu').mmenu();
  }

  initBackgroundParallax() {
    let isMobile = this.isMobile();

    $(window).stellar({
      horizontalScrolling: false,
      hideDistantElements: true,
      verticalScrolling: !isMobile.any(),
      scrollProperty: 'scroll',
      responsive: true
    });
  }

  initMagnificPopup() {
    if (this.anyPopupExists()) {
      $('.popup-img').magnificPopup({
        type:'image',
        gallery: {
            enabled: true,
        }
      });

      $('.popup-img-single').magnificPopup({
        type:'image',
        gallery: {
            enabled: false,
        }
      });

      $('.popup-iframe').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        preloader: false,
        fixedContentPos: false
      });

      $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
      });
    }
  }

  initTabShow() {
    $('#myTab a').on('click', e => {
      e.preventDefault();

      $(e.currentTarget).tab('show');
    });
  }

  initDatePicker() {
    if ($('.datepicker').length > 0) {
      $('.datepicker').datetimepicker();
    }
  }

  initFeaturedPropertiesCarousel() {
    if ($('.feature_property_slider').length > 0) {
      $('.feature_property_slider').owlCarousel({
        loop: false,
        margin: 10,
        center: false,
        dots: true,
        nav: true,
        rtl: false,
        autoplayHoverPause: false,
        autoplay: false,
        singleItem: true,
        smartSpeed: 1200,

        navText: [
          '<i class="fa fa-arrow-left"></i>',
          '<i class="fa fa-arrow-right"></i>'
        ],

        responsive: this.buildResponsiveSettingsFeaturedPropertiesCarousel()
      });
    }
  }

  initFeaturedPropertiesSidebarCarousel() {
    if($('.sidebar_feature_property_slider').length){
      $('.sidebar_feature_property_slider').owlCarousel({
          loop: true,
          margin: 15,
          dots: true,
          nav: true,
          rtl: false,
          autoplayHoverPause: false,
          autoplay: true,
          smartSpeed: 1000,
          singleItem: true,

          navText: [
            '<i class="flaticon-left-arrow-1"></i>',
            '<i class="flaticon-right-arrow"></i>'
          ],

          responsive: this.buildResponsiveSettingsFeaturedPropertiesSidebarCarousel()
      })
    }
  }

  initTopCarousel() {
    let hasRealSlides = $('.single-slide').length === 0;

    if ($('.banner-style-one').length > 0) {
      $('.banner-style-one').owlCarousel({
          loop: hasRealSlides,
          items: 1,
          margin: 0,
          dots: true,
          nav: true,
          animateOut: 'slideOutRight',
          animateIn: 'slideInLeft',
          active: hasRealSlides,
          smartSpeed: 1000,
          autoplay: hasRealSlides,
      });

      $('.banner-carousel-btn .left-btn').on('click', () => {
        $('.banner-style-one').trigger('next.owl.carousel');

        return false;
      });

      $('.banner-carousel-btn .right-btn').on('click', () => {
        $('.banner-style-one').trigger('prev.owl.carousel');

        return false;
      });
    }
  }

  initPartnersCarousel() {
    if ($('.partner_slider').length > 0) {
      $('.partner_slider').owlCarousel({
        loop: true,
        margin: 0,
        dots: true,
        nav: false,
        rtl: false,
        autoplayHoverPause: false,
        autoplay: false,
        singleItem: true,
        smartSpeed: 1200,
        items: 2,

        navText: [
          '<i class="fa fa-arrow-left"></i>',
          '<i class="fa fa-arrow-right"></i>'
        ],

        //responsive: this.buildResponsiveSettingsPartnersCarousel()
      });
    }
  }

  initRangeSliders() {
    let rangeSelectorsEl = $('.range-selector');

    rangeSelectorsEl.click( e => {
      $(e.currentTarget).find( '.dd_content2').toggle();
    });

    this._initPriceSlider();
    this._initAreaSlider();
  }

  initSearchPanel() {
    $('#show_advbtn').click( () => $('.dropdown-content').toggle());
    $('#hide_advbtn').click( () => $('.dropdown-content').hide());

    $('#show_advbtn').click( () => $('body').toggleClass('mobile_ovyh'));
  }

  initFilterButtons() {
    $('.filter_open_btn').on('click',
      () => $('.sidebar_content_details.style3').addClass('sidebar_ml0')
    );

    $('.filter_closed_btn').on('click',
      () => $('.sidebar_content_details.style3').removeClass('sidebar_ml0')
    );

    $('.filter_open_btn').on('click', () => $('body').addClass('body_overlay'));
    $('.filter_closed_btn').on('click', () => $('body').removeClass('body_overlay'));
  }

  initOverlay() {
    $('.overlay_close').on('click', () => $('.white_goverlay').toggle(500));
  }

  isMobile() {
    return {
      isAndroid() {
        return navigator.userAgent.match(/Android/i);
      },

      isiOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },

      isOpera() {
        return navigator.userAgent.match(/Opera Mini/i);
      },

      isWindows() {
        return navigator.userAgent.match(/IEMobile/i);
      },

      isBlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i);
      },

      any(){
        return this.isAndroid() ||
               this.isiOS() ||
               this.isOpera() ||
               this.isWindows() ||
               this.isBlackBerry();
      }
    };
  }

  anyPopupExists() {
    return $('.popup-img').length > 0 ||
           $('.popup-iframe').length > 0 ||
           $('.popup-img-single').length > 0;
  }

  buildResponsiveSettingsFeaturedPropertiesCarousel() {
    return {
      0: {
        items: 1,
        center: false
      },

      480:{
        items:1,
        center: false
      },

      600: {
        items: 1,
        center: false
      },

      768: {
        items: 2
      },

      992: {
        items: 2
      },

      1200: {
        items: 2
      },

      1280: {
        items: 3
      }
    }
  }

  buildResponsiveSettingsFeaturedPropertiesSidebarCarousel() {
    return {
      320:{
        items: 1,
        center: false
      },

      480:{
        items: 1,
        center: false
      },

      600: {
        items: 1,
        center: false
      },

      768: {
        items: 1
      },

      992: {
        items: 1
      },

      1200: {
        items: 1
      }
    };
  }

  buildResponsiveSettingsPartnersCarousel() {
    return {
      0: {
        items: 2,
        center: false
      },

      480:{
        items: 2,
        center: false
      },

      600: {
        items: 2,
        center: false
      },

      768: {
        items: 2
      },

      992: {
        items: 2
      },

      1200: {
        items: 2
      },

      1280: {
        items: 2
      }
    }
  }

  initModalityButtons() {
    let buySearchBtn = $('#buy-search-btn');
    let rentSearchBtn = $('#rent-search-btn');
    let modalityField = $('input[name="modality"]')

    buySearchBtn.click( () => modalityField.val( 1));
    rentSearchBtn.click( () => modalityField.val( 2));
  }

  initGeoLocation() {
    this.nearMeEl = $('#near-me');
    this.nearLocationField = $('input[name="nearLocation"]');

    this.nearMeEl.click( e => this._toggleNearMeLocation( e));
  }

  /**
   * Elimina todos los values vacios o en blanco antes de hacer el submit
   * de un formulario.
   */
  initFormCleanup() {
    $("form").submit( e => {
      const form = $(e.currentTarget);
      const allFields = form.find( 'input, select');

      allFields.each( (_, el) => {
        const field = $(el);

        if (this._searchFieldHasDefaultValue( field)) {
          field.attr( 'disabled', 'disabled');
        }
      });

      return true;
    });
  }

  _initPriceSlider() {
    let priceRangeEl = $('.price-range');
    let startPriceEl = $('.price-start');
    let endPriceEl = $('.price-end');

    priceRangeEl.slider({
      range: true,
      min: 0,
      max: MAX_PRICE,
      values: [0, MAX_PRICE],

      slide: (_, ui) => {
        startPriceEl.val( this._formatNumber( ui.values[0]));
        endPriceEl.val( this._formatNumber( ui.values[1]));
      }
    });

    startPriceEl.change(
      e => {
        priceRangeEl.slider( 'values', 0, this._formatNumber( $(e.currentTarget).val()));
      }
    );

    endPriceEl.change(
      e => {
        priceRangeEl.slider( 'values', 1, this._formatNumber( $(e.currentTarget).val()));
      }
    );
  }

  _initAreaSlider() {
    let areaRangeEl = $('.area-range');
    let minAreaEl = $('.area-min');
    let maxAreaEl = $('.area-max');

    areaRangeEl.slider({
      range: true,
      min: 0,
      max: MAX_AREA,
      values: [0, MAX_AREA],

      slide: (_, ui) => {
        minAreaEl.val( this._formatNumber( ui.values[0]));
        maxAreaEl.val( this._formatNumber( ui.values[1]));
      }
    });

    minAreaEl.change(
      e => {
        areaRangeEl.slider( 'values', 0, this._formatNumber( $(e.currentTarget).val()));
      }
    );

    maxAreaEl.change(
      e => {
        areaRangeEl.slider( 'values', 1, this._formatNumber( $(e.currentTarget).val()));
      }
    );
  }

  _manageScroll( e) {
    if ($('.scroll-to-top').length > 0) {
      let strickyScrollPos = 100;

      if ($(window).scrollTop() > strickyScrollPos) {
        $('.scroll-to-top').fadeIn( 500);
      } else if ($(e.currentTarget).scrollTop() <= strickyScrollPos) {
        $('.scroll-to-top').fadeOut( 500);
      }
    };

    if ($('.stricky').length > 0) {
      let headerScrollPos = $('.header-navigation').next().offset().top;
      let stricky = $('.stricky');

      if ($(window).scrollTop() > headerScrollPos) {
        stricky.removeClass('slideIn animated');
        stricky.addClass('stricky-fixed slideInDown animated');
      } else if (e && $(e.currentTarget).scrollTop() <= headerScrollPos) {
        stricky.removeClass('stricky-fixed slideInDown animated');
        stricky.addClass('slideIn animated');
      }
    }
  }

  _formatNumber( value) {
    return value.toLocaleString( undefined, {}).replace( /\,/g, '.');
  }

  _toggleNearMeLocation( e) {
    if (this.nearMeEl.is( ':checked')) {
      navigator.geolocation.getCurrentPosition(
        position => this._geoLocationSuccess( position),
        error => this._geoLocationError( error)
      );

      // SOLO PARA DEBUG!!!!!!!!!!!!

      //this._geoLocationSuccess( null);
    } else {
      this.nearLocationField.val( '');
    }
  }

  _geoLocationSuccess( position) {
    let latitude = '-40.572930';
    let longitude = '-69.364618';

    this.nearLocationField.val( latitude + ';' +  longitude);
  }

  _geoLocationError( error) {
    this.nearLocationField.val( '');

    console.error( error);
  }

  _searchFieldHasDefaultValue( field) {
    return field.val() === '' ||
           (field.attr( 'name') === 'priceStart' && field.val() === '0') ||
           (field.attr( 'name') === 'priceEnd' && field.val() === '100.000.000');
  }
}

$(function() {
  (new MainController()).initialize();
});
