const MY_MAPTYPE_ID = 'style_KINESB';

/**
 * Mapa de Gmap
 */
export class Gmap
{
  constructor( elementId, locations, options) {
    this.elementId = elementId;
    this.locations = locations;
    this.latLngBounds = new google.maps.LatLngBounds();

    if (!('push' in this.locations)) {
      this.locations = [this.locations];
    }

    this.center = this._buildCenter( this.locations[0]);
    this.mapOptions = this._buildMapOptions( options);
  }

  show() {
    this.map = this._createMap();

    this._buildMarkers();
    this._fitToBounds();
  }

  _buildMapOptions( options) {
    options = options || {};

    return Object.assign( this._getDefaultMapOptions(), options);
  }

  _buildCenter() {
    return new google.maps.LatLng( this.locations[0].lat, this.locations[0].lng);
  }

  _createMap() {
    const mapEl = document.getElementById( this.elementId);
    const map = new google.maps.Map( mapEl, this.mapOptions);

    map.mapTypes.set( MY_MAPTYPE_ID, this._buildCustomMapType());

    return map;
  }

  _buildCustomMapType() {
    return new google.maps.StyledMapType( [], {
      name: MY_MAPTYPE_ID
    })
  }

  _buildMarkers() {
    this.locations.forEach(
      location => this._buildMarker( location)
    );
  }

  _fitToBounds() {
    if (this.locations.length > 1) {
      this.map.setCenter( this.latLngBounds.getCenter());
      this.map.fitBounds( this.latLngBounds);
    }
  }

  _buildMarker( location) {
    const marker = new google.maps.Marker({
      map: this.map,
      position: this._buildPosition( location),
      title: location.title,
      icon: this._buildIcon( location)
    });

    if ('content' in location) {
      marker.addListener( 'click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: location.content,
        });

        infowindow.open( this.map, marker);
        this.map.setCenter( marker.getPosition());
      });
    }

    if ('targetUrl' in location) {
      marker.addListener( 'click', () => {
        window.location.href = location.targetUrl;
      });
    }

    this.latLngBounds.extend( marker.position);

    return marker;
  }

  _buildPosition( location) {
    return new google.maps.LatLng( location.lat, location.lng);
  }

  _buildIcon( location) {
    let result = null;

    if ('color' in location) {
      result = { url: `http://maps.google.com/mapfiles/ms/icons/${location.color}.png` };
    }

    return result;
  }

  _buildFeatureOptions() {
    return []
  }

  _getDefaultMapOptions() {
    return {
      zoom: 11,
      mapTypeControl: true,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.LEFT_TOP,
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
      },
      mapTypeId: MY_MAPTYPE_ID,
      scaleControl: false,
      streetViewControl: false,
      center: this.center,
    };
  }
}
